import React from "react";
import cx from "classnames";
import qs from "query-string";

import _U from "@util/utilities";

export default class Paging extends React.Component {
    handlePageClicked(page) {
        this.props.pageCallback && this.props.pageCallback(page < 1 ? 1 : page);
    }

    render() {
        const { paging } = this.props;
        const pagingItems = [];
        if (paging) {
            for (let i = paging.startPageNo; i <= paging.endPageNo; i++) {
                pagingItems.push(
                    <li className={cx({ active: paging.pageNo === i })} key={i}>
                        <a href="#" onClick={this.handlePageClicked.bind(this, i)}>
                            {i}
                        </a>
                    </li>
                );
            }
        }

        return (
            <div className="pagination radius-5 d-block text-center">
                {paging && (
                    <ul>
                        {/* <li  onClick={this.handlePageClicked.bind(this, 1)}>
                            <a className="page-link">««</a>
                        </li> */}
                        <li>
                            <a href="#" onClick={this.handlePageClicked.bind(this, paging.prevBlockNo)}>
                                <i className="fas fa-long-arrow-alt-left me-2"></i>
                            </a>
                        </li>

                        {pagingItems}

                        <li>
                            <a href="#" onClick={this.handlePageClicked.bind(this, paging.nextBlockNo)}>
                                <i className="fas fa-long-arrow-alt-right ms-2"></i>
                            </a>
                        </li>
                        {/* <li  onClick={this.handlePageClicked.bind(this, paging.finalPageNo)}>
                            <a className="page-link">»»</a>
                        </li> */}
                    </ul>
                )}
            </div>
        );
    }
}

export const ToPage = (location, navigate, params) => {
    navigate(`${location.pathname}?q=${_U.encode(params)}`);
};

export const GetParams = (location) => {
    const { search } = location;
    const p = qs.parse(search);
    if (!p.q) return null;
    return _U.decode(p.q);
};
