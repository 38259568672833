import React, { useState, useContext } from "react";
import swal from "sweetalert";
import { useNavigate, Link } from "react-router-dom";

import { AppContext } from "@component/AppProvider";

import _CFG from "@config/defaults";
import _API from "@util/api";
import _CK from "@util/cookie";
import _U from "@util/utilities";

export default () => {
    const { saveIam } = useContext(AppContext);
    const navigate = useNavigate();

    const [data, setData] = useState({});

    const onSubmit = (e) => {
        e.preventDefault();
        try {
            if (!data.name) throw { message: "Please enter a your name" };
            if (!data.mobile) throw { message: "Please enter a your phone number" };
            if (!data.email) throw { message: "Please enter a your email address" };
            if (!_U.isEmail(data.email)) throw { message: "Please enter a valid email address" };
            if (!data.passwd) throw { message: "Please enter a your password" };
            if (!data.passwdRe) throw { message: "Please enter a your confirm password" };
            if (data.passwd !== data.passwdRe) throw { message: "Please verify your password." };

            _API.post({ path: "/join", data })
                .then((res) => res.data)
                .then((data) => {
                    const { accessKey, cdn, iam } = data;
                    if (iam?.status !== "Y") {
                        swal("접근 권한이 없습니다.");
                        return;
                    }

                    saveIam(iam);
                    _CK.setAuth(accessKey);
                    navigate("/", { replace: true });
                })
                .catch((e) => _API.alert(e));
        } catch (e) {
            swal({
                title: "알림",
                text: e.message,
            });
        }
    };

    return (
        <section className="bg-light p-0">
            <div className="container d-flex flex-column">
                <div className="row align-items-center justify-content-center min-vh-100">
                    <div className="col-md-8 col-lg-7 col-xl-5">
                        <div className="card p-2-0 p-sm-2-5 my-2-0">
                            <div className="mb-4 text-center">
                                <h2 className="font-weight-normal mb-0">
                                    <span className="text-primary">Register</span>
                                </h2>
                                <p className="mb-0">Register to your account.</p>
                            </div>

                            <form onSubmit={onSubmit}>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="name"
                                                placeholder="Your name"
                                                value={data.name || ""}
                                                onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Mobile</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="mobile"
                                                placeholder="Your phone number"
                                                value={data.mobile || ""}
                                                onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <hr />
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Email Address (ID)</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="email"
                                                placeholder="Your email address"
                                                value={data.email || ""}
                                                onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Password</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                name="passwd"
                                                placeholder="Your password"
                                                value={data.passwd || ""}
                                                onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Confirm Password</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                name="passwdRe"
                                                placeholder="Your confirm password"
                                                value={data.passwdRe || ""}
                                                onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-2">
                                    <div className="col-md-12 center">
                                        <button type="submit" className="butn style-one fill d-block w-100 mt-4 mb-0">
                                            Register
                                        </button>
                                    </div>
                                </div>

                                <div className="text-center text-small mt-4">
                                    <span>
                                        Already have an account?{" "}
                                        <Link to="/login" className="text-primary">
                                            Login
                                        </Link>
                                    </span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
