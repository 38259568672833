import React from "react";
import { Link } from "react-router-dom";

import _CFG from "@config/defaults";

export default () => {
    return (
        <footer className="pt-20 mt-n10">
            <div className="container">
                <div className="row mb-2-9 mb-sm-8">
                    <div className="col-lg-8">
                        <div className="heading-animation animation-style1">
                            <h5 className="text-white h6 title-font">Ready to Work With Us ?</h5>
                            <h4 className="text-white display-22 display-sm-12 display-md-8 mb-1-9 mb-sm-2-9 title-font">info@wrightbrothers.kr</h4>
                            <div className="social-icon06">
                                <ul className="mb-0 ps-0 list-unstyled">
                                    <li>
                                        <a href="#!">
                                            <i className="fab fa-facebook-f"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#!">
                                            <i className="fab fa-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#!">
                                            <i className="fab fa-dribbble"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#!">
                                            <i className="fab fa-linkedin-in"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row border-bottom border-color-light-white mb-1-9 pb-2-9 mt-n1-9">
                    <div className="col-sm-6 mt-1-9">
                        <div>
                            <div className="row mt-n2-6">
                                <div className="col-lg-6 mt-2-6">
                                    <div>
                                        <h5 className="text-white title-font">Address</h5>
                                        <p>
                                            54, Noksapyeong-daero 11-gil <br />
                                            Yongsan-gu, Seoul
                                            <br />
                                            Republic of Korea
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-2-6">
                                    <div>
                                        <h5 className="text-white title-font">Contact</h5>
                                        <p className="mb-2">wrightbrothers.kr</p>
                                        <p>(+82) 02-797-0521</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 mt-1-9">
                        <div className="footer-logo text-start text-sm-end">
                            <a href="index.html">
                                <img src="/assets/img/logo-white.png" alt="logo" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-center mb-1-9">
                            <p className="text-white opacity7">
                                &copy; <span className="current-year"></span> CarbonPay is Powered by{" "}
                                <a href="https://wrightbrothers.kr" className="text-white" target="blank">
                                    {_CFG.title}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};
