import React, { Fragment, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import cx from "classnames";
import swal from "sweetalert";

import _API from "@util/api";
import _U from "@util/utilities";
import _CK from "@util/cookie";

export default () => {
    const { code } = useParams();
    const navigate = useNavigate();

    const [target, setTarget] = useState(null);
    const [amount, setAmount] = useState(0);

    const [point, setPoint] = useState(0);

    const loadUser = () => {
        _API.get({ path: `/v1/user/pay/transfer/${code}` })
            .then((res) => res.data)
            .then((data) => {
                if (!data) {
                    swal({ title: "알림", text: "존재하지 않는 계좌정보 입니다.", button: "확인" }).then(() => {
                        navigate("/", { replace: true });
                    });
                    return;
                }
                setTarget(data);
            })
            .catch((e) => _API.alert(e));
    };

    const loadData = () => {
        _API.get({ path: "/v1/user/pay/accounts" })
            .then((res) => res.data)
            .then((data) => {
                const { point } = data;
                setPoint(point);
            })
            .catch((e) => _API.alert(e));
    };

    const handleOnTransfer = () => {
        _API.post({ path: `/v1/user/pay/transfer/${code}`, data: { amount } })
            .then((res) => {
                swal({ title: "알림", text: "송금이 완료되었습니다.", button: "확인" }).then(() => {
                    navigate("/", { replace: true });
                });
            })

            .catch((e) => _API.alert(e));
    };

    useEffect(() => {
        if (!_CK.isLogin()) {
            navigate(`/login?url=${encodeURIComponent(`/wallet/${code}`)}`, { replace: true });
        } else {
            loadUser();
            loadData();
        }
    }, []);

    return (
        <Fragment>
            {/* <!-- PAGE TITLE ================================================== --> */}
            <section className="page-title-section bg-img cover-background left-overlay-dark" data-overlay-dark="8" style={{ backgroundImage: "url(/assets/sample/01.jpg)" }}>
                <div className="d-inline-block p-3 rounded-circle bg-primary position-absolute z-index-1 top-35 right-20 ani-top-bottom"></div>
                <div className="d-inline-block p-2 rounded-circle bg-secondary position-absolute z-index-1 top-25 left-10 ani-top-bottom"></div>
                <div className="d-inline-block bg-white p-3 ani-zoomin rounded-circle position-absolute bottom-5 left-10 opacity1 z-index-1"></div>
            </section>

            {/* <!-- PROFILE ================================================== --> */}
            <section className="account-block overflow-visible z-index-9 py-8 bg-light">
                <div className="container">
                    <div className="row mt-n5">
                        <div className="col-lg-4 col-xl-3 mt-5">
                            <div className="account-sidebar">
                                <div className="account-widget personal-data">
                                    <img src="/assets/amava/img/avatar/avatar-04.jpg" alt="..." className="rounded-circle w-90px mb-4" />
                                    <div className="mb-3">
                                        <h4 className="h5">{target?.name}</h4>
                                        <span className="display-30">{target?.email}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-xl-9 mt-5">
                            <div className="right-content account-widget mb-1-9 ms-xl-3">
                                <div className="list-title pb-5 p-0 mb-1-9 mb-md-5">
                                    <div className="mb-3">
                                        <h3 className="mb-1">Transfer Points</h3>
                                        <span>이체 가능 포인트 : {_U.addComma(point)}P</span>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>송금액</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    name="name"
                                                    placeholder="송금액을 입력해 주세요."
                                                    value={amount}
                                                    onChange={(e) => setAmount(e.target.value)}
                                                    min={0}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-12 center">
                                            <button type="button" className="butn style-one fill d-block w-100 mt-4 mb-0" onClick={handleOnTransfer}>
                                                송금하기
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};
