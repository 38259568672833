import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { AppContext } from "@component/AppProvider";
import _CK from "@util/cookie";
import _CFG from "@config/defaults";

export default () => {
    const { iam, saveIam } = useContext(AppContext);
    const navigate = useNavigate();

    const handleLogout = (e) => {
        e.preventDefault();

        saveIam(null);
        _CK.clearAuth();

        navigate("/", { replace: true });
    };

    useEffect(() => {}, [iam]);

    const isLogin = _CK.isLogin();

    return (
        <header className="position-absolute w-100 sm-position-relative transparent-header">
            <div className="navbar-default border-bottom border-color-light-white">
                {/* <!-- start top search --> */}
                <div className="top-search bg-black-opacity-light">
                    <div className="container lg-container">
                        <form className="search-form" action="search.html" method="GET" acceptCharset="utf-8">
                            <div className="input-group">
                                <span className="input-group-addon cursor-pointer">
                                    <button className="search-form_submit fas fa-search text-white" type="submit"></button>
                                </span>
                                <input type="text" className="search-form_input form-control" name="s" autoComplete="off" placeholder="Search user Wallets..." />
                                <span className="input-group-addon close-search">
                                    <i className="fas fa-times mt-2"></i>
                                </span>
                            </div>
                        </form>
                    </div>
                </div>
                {/* <!-- end top search --> */}

                <div className="container lg-container">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-12">
                            <div className="menu_area alt-font">
                                <nav className="navbar navbar-expand-lg navbar-light p-0">
                                    <div className="navbar-header navbar-header-custom">
                                        {/* <!-- start logo --> */}
                                        <Link to="/" className="navbar-brand inner-logo">
                                            <img id="logo" src="/assets/img/logo-white.png" alt="logo" />
                                        </Link>
                                        {/* <!-- end logo --> */}
                                    </div>

                                    <div className="navbar-toggler"></div>

                                    {/* <!-- start menu area --> */}
                                    <ul className="navbar-nav ms-auto" id="nav">
                                        <li>
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li>
                                            <Link to="/about">About Us</Link>
                                        </li>
                                        {isLogin ? (
                                            <li>
                                                <a href="#" onClick={handleLogout}>
                                                    Sign Out
                                                </a>
                                            </li>
                                        ) : (
                                            <li>
                                                <Link to="/login">Sign In</Link>
                                            </li>
                                        )}
                                    </ul>
                                    {/* <!-- end menu area --> */}

                                    {/* <!-- start attribute navigation --> */}
                                    <div className="attr-nav">
                                        <ul>
                                            <li className="search">
                                                <a href="#!">
                                                    <i className="fas fa-search"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* <!-- end attribute navigation --> */}
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};
