import React, { Fragment, useContext, useEffect, useState } from "react";
import moment from "moment";
import cx from "classnames";
import QRCode from "react-qr-code";
import styled from "styled-components";

import _API from "@util/api";
import _U from "@util/utilities";

import { AppContext } from "@component/AppProvider";
import Paging from "@component/UI/Paging";
import useIam from "@hook/useIam";

export default () => {
    const { iam } = useContext(AppContext);

    const [point, setPoint] = useState(0);
    const [accounts, setAccounts] = useState([]);
    const [code, setCode] = useState(null);

    const [keyword, setKeyword] = useState("");
    const [list, setList] = useState([]);
    const [paging, setPaging] = useState(null);

    const [isShowQrCode, setIsShowQrCode] = useState(false);

    const loadData = () => {
        _API.get({ path: "/v1/user/pay/accounts" })
            .then((res) => res.data)
            .then((data) => {
                const { point, accounts } = data;
                setPoint(point);
                setAccounts(accounts);
            })
            .catch((e) => _API.alert(e));
    };

    const loadList = (data) => {
        _API.get({
            path: "/v1/user/pay/transactions",
            data,
        })
            .then((res) => res.data)
            .then((data) => {
                const { list, paging } = data;
                setList(list);
                setPaging(paging);
            });
    };

    const movePage = (page) => {
        loadList({ keyword, page });
    };

    const handleOnQrCode = () => {
        _API.iam()
            .then((res) => res.data)
            .then((data) => {
                const { iam } = data;
                const { code } = iam;
                setCode(code);
                setTimeout(() => setIsShowQrCode(true), 500);
            })
            .catch((e) => _API.alert(e));
    };

    useIam({
        onSuccess: (data) => {
            if (!data) return;
            const { iam } = data;
            const { code } = iam;
            setCode(code);
        },
    });

    useEffect(() => {
        loadData();
        loadList({ keyword, page: 1 });
    }, []);

    let virtualNo = paging ? paging.virtualRecordNo : -1;

    return (
        <Fragment>
            {/* <!-- PAGE TITLE ================================================== --> */}
            <section className="page-title-section bg-img cover-background left-overlay-dark" data-overlay-dark="8" style={{ backgroundImage: "url(/assets/sample/01.jpg)" }}>
                <div className="d-inline-block p-3 rounded-circle bg-primary position-absolute z-index-1 top-35 right-20 ani-top-bottom"></div>
                <div className="d-inline-block p-2 rounded-circle bg-secondary position-absolute z-index-1 top-25 left-10 ani-top-bottom"></div>
                <div className="d-inline-block bg-white p-3 ani-zoomin rounded-circle position-absolute bottom-5 left-10 opacity1 z-index-1"></div>
            </section>

            {/* <!-- PROFILE ================================================== --> */}
            <section className="account-block overflow-visible z-index-9 py-8 bg-light">
                <div className="container">
                    <div className="row mt-n5">
                        <div className="col-lg-4 col-xl-3 mt-5">
                            <div className="account-sidebar">
                                <div className="account-widget personal-data">
                                    <img src="/assets/amava/img/avatar/avatar-04.jpg" alt="..." className="rounded-circle w-90px mb-4" />
                                    <div className="mb-3">
                                        <h4 className="h5">{iam?.name}</h4>
                                        <span className="display-30">{iam?.mobile}</span>
                                    </div>
                                    <a href="#" className="butn style-one fill small" onClick={handleOnQrCode}>
                                        QR Code
                                    </a>
                                </div>
                                <div className="account-widget personal-details">
                                    <div className="widget-title">
                                        <h4>My Account</h4>
                                    </div>
                                    <ul className="list-style16">
                                        <li>
                                            <a href="#">
                                                <div>
                                                    <i className="fa-solid fa-circle-user text-primary me-2 display-30"></i>My Profile
                                                </div>
                                                <div>
                                                    <i className="fa-solid fa-chevron-right"></i>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <div>
                                                    <i className="fa-solid fa-gear text-primary me-2 display-30"></i>Setting
                                                </div>
                                                <div>
                                                    <i className="fa-solid fa-chevron-right"></i>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <div>
                                                    <i className="fa-regular fa-credit-card text-primary me-2 display-30"></i>Billing
                                                </div>
                                                <div>
                                                    <i className="fa-solid fa-chevron-right"></i>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <div>
                                                    <i className="fa-regular fa-bell text-primary me-2 display-30"></i>Notifications
                                                </div>
                                                <div>
                                                    <i className="fa-solid fa-chevron-right"></i>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-xl-9 mt-5">
                            <div className="right-content account-widget mb-1-9 ms-xl-3">
                                <div className="list-title pb-5 p-0 mb-1-9 mb-md-5">
                                    <div className="mb-3">
                                        <h3 className="mb-1">My Accounts</h3>
                                        <span>잔여 포인트 : {_U.addComma(point)}P</span>
                                    </div>
                                    <p>
                                        포인트는 각 후원사를 통해 집계된 포인트의 합산입니다.
                                        <br />
                                        포인트는 결제 및 송금등을 통해 사용 가능합니다.
                                    </p>
                                </div>
                                <div className="list-title pb-5 p-0 mb-1-9 mb-md-5">
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">계좌번호</th>
                                                    <th scope="col">계좌명</th>
                                                    <th scope="col">포인트 잔액</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {accounts?.map((item, i) => (
                                                    <tr key={i}>
                                                        <th scope="row">{i + 1}</th>
                                                        <td>{item.code}</td>
                                                        <td>{item.name}</td>
                                                        <td>{_U.addComma(item.amount)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="list-title pb-5 p-0 mb-1-9 mb-md-5">
                                    <h4 className="h5 mb-3">Transactions</h4>
                                    <div className="row mt-n3">
                                        <div className="col-md-12 col-xl-12 mt-12 position-relative elements-block">
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">거래ID</th>
                                                            <th scope="col">주문ID</th>
                                                            <th scope="col">거래종류</th>
                                                            <th scople="col">변동전</th>
                                                            <th scople="col">변동액</th>
                                                            <th scople="col">변동후</th>
                                                            <th scople="col">거래일</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {list?.map((item, i) => (
                                                            <tr key={i}>
                                                                <th scope="row">{virtualNo--}</th>
                                                                <td>{item.tid}</td>
                                                                <td>{item.oid}</td>
                                                                <td>
                                                                    <span className={cx({ badge: true, "bg-primary": item.type === "IN", "bg-danger": item.type !== "IN" })}>
                                                                        {item.type === "IN" ? "입금" : "출금"}
                                                                    </span>
                                                                </td>
                                                                <td>{_U.addComma(item.current)}P</td>
                                                                <td>{_U.addComma(item.amount)}P</td>
                                                                <td>{_U.addComma(item.balance)}P</td>
                                                                <td>{moment(item.regDate).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {paging && (
                                            <div className="col-md-12 col-xl-12 mt-12 position-relative elements-block">
                                                <Paging paging={paging} pageCallback={movePage} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {isShowQrCode && (
                <QRWrapper onClick={() => setIsShowQrCode(false)}>
                    <QRCode size={300} style={{ height: "300px", width: "300px" }} value={`${process.env.FRONT_URL}/wallet/${code}`} viewBox={`0 0 256 256`} />
                </QRWrapper>
            )}
        </Fragment>
    );
};

const QRWrapper = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;
