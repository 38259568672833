import React, { Fragment } from "react";

export default () => {
    return (
        <Fragment>
            {/* <!-- PAGE TITLE ================================================== --> */}
            <section className="page-title-section bg-img cover-background left-overlay-dark" data-overlay-dark="8" style={{ backgroundImage: "url(/assets/sample/01.jpg)" }}>
                <div className="container lg-container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>About Us</h1>
                        </div>
                        <div className="col-md-12">
                            <ul className="ps-0">
                                <li>
                                    <a href="demo-1.html">Home</a>
                                </li>
                                <li>
                                    <a href="#!">About Us</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="d-inline-block p-3 rounded-circle bg-primary position-absolute z-index-1 top-35 right-20 ani-top-bottom"></div>
                <div className="d-inline-block p-2 rounded-circle bg-secondary position-absolute z-index-1 top-25 left-10 ani-top-bottom"></div>
                <div className="d-inline-block bg-white p-3 ani-zoomin rounded-circle position-absolute bottom-5 left-10 opacity1 z-index-1"></div>
            </section>

            {/* <!-- ABOUT ================================================== --> */}
            <section>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 mb-5 mb-lg-0 hover-style3 text-center text-lg-start">
                            <div className="px-3 px-md-5 px-lg-0">
                                <img src="/assets/amava/img/content/content-10.svg" alt="..." />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="ps-lg-5">
                                <h2 className="mb-3">Make your business rich with the Amava</h2>
                                <p className="display-28 mb-1-8">Powerful tool for your customer to help user find solution for build something great.</p>

                                <p className="mb-1-8">
                                    Cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa officia deserunt mollit anim id est laborum. Cillum dolore eu
                                    fugiat nulla pariatur.
                                </p>

                                <ul className="list-style6 ps-0 mb-2-2">
                                    <li>Exclusive design</li>
                                    <li>We Provide Awesome Services</li>
                                    <li>Your business deserves best design</li>
                                </ul>

                                <a href="#!" className="butn style-one fill">
                                    Get Started
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- COUNTER ================================================== --> */}
            <section className="bg-primary-solid counter-box02">
                <span className="right-shape d-none d-xl-block">
                    <img src="/assets/amava/img/content/object-01.png" />
                </span>
                <span className="left-shape"></span>
                <div className="container position-relative z-index-99">
                    <div className="w-95 w-sm-90 w-lg-50 text-center mx-auto mb-5 mb-md-7">
                        <span className="d-block mb-2 text-white font-weight-600">We provide best</span>
                        <h2 className="text-white">The bright and easiest help to grow your business</h2>
                    </div>

                    <div className="row mt-n5">
                        <div className="col-6 col-md-3 mt-5">
                            <div className="text-center">
                                <h4 className="text-white h1">
                                    <span className="countup">15</span>+
                                </h4>
                                <p className="mb-0 text-white">Experience Years</p>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 mt-5">
                            <div className="text-center">
                                <h4 className="text-white h1">
                                    <span className="countup">634</span>+
                                </h4>
                                <p className="mb-0 text-white">Happy clients</p>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 mt-5">
                            <div className="text-center">
                                <h4 className="text-white h1">
                                    <span className="countup">951</span>+
                                </h4>
                                <p className="mb-0 text-white">Finished Projects</p>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 mt-5">
                            <div className="text-center">
                                <h4 className="text-white h1">
                                    <span className="countup">5</span>k
                                </h4>
                                <p className="mb-0 text-white">Skilled Experts</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- TEAM ================================================== --> */}
            <section className="bg-grey">
                <div className="container">
                    <div className="w-md-85 w-lg-65 w-xl-50 text-center mx-auto mb-6 mb-lg-8">
                        <h2 className="mb-0">Team members</h2>
                    </div>

                    <div className="row mt-n5">
                        <div className="col-md-4 mt-5 text-center">
                            <div className="team-style02 mx-lg-3">
                                <div className="team-img">
                                    <img src="/assets/amava/img/team/team-13.jpg" alt="" />
                                </div>
                                <div className="team-icons clearfix">
                                    <a href="#!">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                    <a href="#!">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                </div>

                                <div className="py-4 text-center">
                                    <h6 className="opacity5">Co-Founder</h6>
                                    <h5 className="mb-0">Horothy Hulese</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-5 text-center">
                            <div className="team-style02 mx-lg-3">
                                <div className="team-img">
                                    <img src="/assets/amava/img/team/team-14.jpg" alt="" />
                                </div>
                                <div className="team-icons clearfix">
                                    <a href="#!">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                    <a href="#!">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                </div>

                                <div className="py-4 text-center">
                                    <h6 className="opacity5">Designer</h6>
                                    <h5 className="mb-0">Buckle Giarza</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-5 text-center">
                            <div className="team-style02 mx-lg-3">
                                <div className="team-img">
                                    <img src="/assets/amava/img/team/team-15.jpg" alt="" />
                                </div>
                                <div className="team-icons clearfix">
                                    <a href="#!">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                    <a href="#!">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                </div>

                                <div className="py-4 text-center">
                                    <h6 className="opacity5">Developer</h6>
                                    <h5 className="mb-0">Lenthi Miloler</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};
