import React, { Fragment, useEffect, useContext } from "react";

import { AppContext } from "@component/AppProvider";

import _CK from "@util/cookie";

import Wallet from "@component/Wallets/Profile";

export default () => {
    const { iam } = useContext(AppContext);

    useEffect(() => {}, [iam]);

    if (!_CK.isLogin()) return <UnLoginContent />;

    return <Wallet />;
};

const UnLoginContent = () => {
    return (
        <Fragment>
            {/* <!-- BANNER ================================================== --> */}
            <div className="main-banner-style16 full-screen sm-top-position">
                <div className="bg-img cover-background dark-overlay" data-overlay-dark="7" style={{ backgroundImage: "url(/assets/sample/01.jpg)" }}>
                    <div className="container h-100vh d-table wow fadeIn">
                        <div className="d-table-cell align-middle">
                            <div className="row align-items-center">
                                <div className="col-lg-7">
                                    <div className="header-text">
                                        <div className="heading-animation animation-style1">
                                            <h1 className="mb-0 text-white text-center text-lg-start title-font lh-1">We Create a Life Free From Carbon.</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- SERVICES ================================================== --> */}
            <section className="mt-n5 overflow-visible bg-transparent z-index-9 p-0">
                <div className="container">
                    <div className="bg-light p-1-9 p-sm-6 p-xl-8 p-xxl-10 rounded">
                        <div className="service-carousel01 owl-carousel owl-theme wow fadeIn" data-wow-delay=".2s">
                            <div className="card card-style23 border-0">
                                <div className="card-body p-1-9 p-xxl-5">
                                    <div className="mb-4 position-relative">
                                        <img src="/assets/amava/img/icon/icon-20.png" alt="..." className="w-auto" />
                                    </div>
                                    <h3 className="h5 mb-3 title-font">
                                        <a href="service-2.html">Unique & Adaptable</a>
                                    </h3>
                                    <p className="mb-3 display-30">The agency will support to develop innovation and technology to startups in many variations.</p>
                                    <a href="service-2.html" className="display-30">
                                        Read More
                                    </a>
                                </div>
                            </div>
                            <div className="card card-style23 border-0">
                                <div className="card-body p-1-9 p-xxl-5">
                                    <div className="mb-4 position-relative">
                                        <img src="/assets/amava/img/icon/icon-21.png" alt="..." className="w-auto" />
                                    </div>
                                    <h3 className="h5 mb-3 title-font">
                                        <a href="service-2.html">Best Performance</a>
                                    </h3>
                                    <p className="mb-3 display-30">The agency will support to develop innovation and technology to startups in many variations.</p>
                                    <a href="service-2.html" className="display-30">
                                        Read More
                                    </a>
                                </div>
                            </div>
                            <div className="card card-style23 border-0">
                                <div className="card-body p-1-9 p-xxl-5">
                                    <div className="mb-4 position-relative">
                                        <img src="/assets/amava/img/icon/icon-22.png" alt="..." className="w-auto" />
                                    </div>
                                    <h3 className="h5 mb-3 title-font">
                                        <a href="service-2.html">Easily Control</a>
                                    </h3>
                                    <p className="mb-3 display-30">The agency will support to develop innovation and technology to startups in many variations.</p>
                                    <a href="service-2.html" className="display-30">
                                        Read More
                                    </a>
                                </div>
                            </div>
                            <div className="card card-style23 border-0">
                                <div className="card-body p-1-9 p-xxl-5">
                                    <div className="mb-4 position-relative">
                                        <img src="/assets/amava/img/icon/icon-23.png" alt="..." className="w-auto" />
                                    </div>
                                    <h3 className="h5 mb-3 title-font">
                                        <a href="service-2.html">Fully Secured</a>
                                    </h3>
                                    <p className="mb-3 display-30">The agency will support to develop innovation and technology to startups in many variations.</p>
                                    <a href="service-2.html" className="display-30">
                                        Read More
                                    </a>
                                </div>
                            </div>
                            <div className="card card-style23 border-0">
                                <div className="card-body p-1-9 p-xxl-5">
                                    <div className="mb-4 position-relative">
                                        <img src="/assets/amava/img/icon/icon-24.png" alt="..." className="w-auto" />
                                    </div>
                                    <h3 className="h5 mb-3 title-font">
                                        <a href="service-2.html">Team Collaboration</a>
                                    </h3>
                                    <p className="mb-3 display-30">The agency will support to develop innovation and technology to startups in many variations.</p>
                                    <a href="service-2.html" className="display-30">
                                        Read More
                                    </a>
                                </div>
                            </div>
                            <div className="card card-style23 border-0">
                                <div className="card-body p-1-9 p-xxl-5">
                                    <div className="mb-4 position-relative">
                                        <img src="/assets/amava/img/icon/icon-25.png" alt="..." className="w-auto" />
                                    </div>
                                    <h3 className="h5 mb-3 title-font">
                                        <a href="service-2.html">Guaranteed Support</a>
                                    </h3>
                                    <p className="mb-3 display-30">The agency will support to develop innovation and technology to startups in many variations.</p>
                                    <a href="service-2.html" className="display-30">
                                        Read More
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- ABOUT US ================================================== --> */}
            <section className="pb-0">
                <div className="container">
                    <div className="row align-items-center mb-2-9 mb-md-10 mt-n5">
                        <div className="col-lg-5 col-xl-6 mt-5 wow fadeIn" data-wow-delay=".2s">
                            <div className="text-center position-relative">
                                <img src="/assets/amava/img/content/about-02.png" alt="..." className="position-relative z-index-9" />
                                <div className="position-absolute top-50 left-50 translate-middle w-100">
                                    <img src="/assets/amava/img/content/shape-5.png" alt="..." />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-xl-6 mt-5 wow fadeIn" data-wow-delay=".3s">
                            <div className="ps-lg-1-9 ps-xl-7">
                                <div className="mb-1-9 mb-md-2-6 heading-animation animation-style1">
                                    <h2 className="mb-0 display-5 font-weight-700 title-font lh-1">We Specialize in Creative Minimalist Projects</h2>
                                </div>
                                <p className="mb-4">
                                    Tansectetur adipisicing elitsedie do eiusmod tempor incididunt labore et dolore magnaaliq voluptatem dolor sit amet, hendrerit omittantur mel et, est ut vidit
                                    animal iracundia luis.
                                </p>
                                <p className="mb-2-6">
                                    Our passion to work hard and deliver excellent results. It could solve your customers and develop innovation. Build your powerful project using advanced Integrate
                                    interface.
                                </p>
                                <div className="d-inline-flex border align-items-center rounded-pill py-3 px-4">
                                    <div className="flex-shrink-0 me-3">
                                        <i className="fa-solid fa-phone text-green display-25"></i>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="mb-0 h6">
                                            Contact No -{" "}
                                            <a href="#!">
                                                <span className="text-green">+44 123-456-789</span>
                                            </a>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center mt-n5">
                        <div className="col-lg-7 col-xl-6 mt-5 order-2 order-lg-1 wow fadeIn" data-wow-delay=".2s">
                            <div className="pe-1-9 pe-xl-7">
                                <div className="mb-1-9 mb-md-2-6 heading-animation animation-style1">
                                    <h2 className="mb-0 display-5 font-weight-700 title-font lh-1">Build Stunning Websites & Apps</h2>
                                </div>
                                <p>
                                    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. Excepteur sint occaecat cupidatat non
                                    proident sunt.
                                </p>
                                <ul className="list-unstyled mb-0 list-style8">
                                    <li>Strong management & security</li>
                                    <li>Solve your problem with excellent results</li>
                                    <li>We Provide Awesome Services</li>
                                    <li>The elements from one design to another</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-5 col-xl-6 mt-5 order-1 order-lg-2 wow fadeIn" data-wow-delay=".3s">
                            <div className="text-center position-relative">
                                <img src="/assets/amava/img/content/about-03.png" alt="..." className="position-relative z-index-9" />
                                <div className="position-absolute top-50 left-50 translate-middle w-100">
                                    <img src="/assets/amava/img/content/shape-6.png" alt="..." />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- CLIENTS ================================================== --> */}
            <section id="elements_image" className="position-relative pb-7">
                <div className="elements-content">
                    <div className="elements-box">
                        <div className="elements-box-left opacity-1">
                            <div className="marquee-container wow fadeIn" data-wow-delay=".2s">
                                <div className="marquee">
                                    <a href="#!">
                                        <img src="/assets/amava/img/partners/01.png" alt="..." />
                                    </a>
                                </div>
                                <div className="marquee">
                                    <a href="#!">
                                        <img src="/assets/amava/img/partners/02.png" alt="..." />
                                    </a>
                                </div>
                                <div className="marquee">
                                    <a href="#!">
                                        <img src="/assets/amava/img/partners/03.png" alt="..." />
                                    </a>
                                </div>
                                <div className="marquee">
                                    <a href="#!">
                                        <img src="/assets/amava/img/partners/04.png" alt="..." />
                                    </a>
                                </div>
                                <div className="marquee">
                                    <a href="#!">
                                        <img src="/assets/amava/img/partners/05.png" alt="..." />
                                    </a>
                                </div>
                                <div className="marquee">
                                    <a href="#!">
                                        <img src="/assets/amava/img/partners/01.png" alt="..." />
                                    </a>
                                </div>
                                <div className="marquee">
                                    <a href="#!">
                                        <img src="/assets/amava/img/partners/02.png" alt="..." />
                                    </a>
                                </div>
                                <div className="marquee">
                                    <a href="#!">
                                        <img src="/assets/amava/img/partners/03.png" alt="..." />
                                    </a>
                                </div>
                                <div className="marquee">
                                    <a href="#!">
                                        <img src="/assets/amava/img/partners/04.png" alt="..." />
                                    </a>
                                </div>
                                <div className="marquee">
                                    <a href="#!">
                                        <img src="/assets/amava/img/partners/05.png" alt="..." />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- TESTIMONIALS ================================================== --> */}
            <section className="testimonials-style01 bg-light">
                <div className="container">
                    <div className="row mb-6 mb-xl-10 border-bottom pb-6 pb-xl-10 align-items-center mt-n1-9 mt-sm-n8">
                        <div className="col-lg-6 mt-1-9 mt-sm-8 wow fadeIn" data-wow-delay=".2s">
                            <div className="pe-xl-7">
                                <div className="mb-3">
                                    <i className="fa-solid fa-star text-primary"></i>
                                    <i className="fa-solid fa-star text-primary"></i>
                                    <i className="fa-solid fa-star text-primary"></i>
                                    <i className="fa-solid fa-star text-primary"></i>
                                    <i className="fa-regular fa-star text-primary"></i>
                                </div>
                                <p className="lh-lg lead">
                                    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunting mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error
                                    sit voluptatem. Exercitation ullamco laboris nisi ut aliquip exea commod consequat.
                                </p>
                                <div>
                                    <h4 className="h6 text-green mb-1">Ambessa Alem</h4>
                                    <span className="display-30">Sr. Manager</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-1-9 mt-sm-8 wow fadeIn" data-wow-delay=".3s">
                            <div className="position-relative d-flex justify-content-center justify-content-lg-end">
                                <div className="right-circle">
                                    <div className="quote-icon">
                                        <img src="/assets/amava/img/icon/quote2.png" alt="..." />
                                    </div>
                                </div>
                                <div className="position-absolute top-50 left-35 left-sm-30 left-md-20 left-lg-50 translate-middle">
                                    <img src="/assets/amava/img/avatar/avatar-18.jpg" alt="..." className="rounded-circle img1" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-n2-9">
                        <div className="col-sm-6 col-lg-3 mt-2-9 wow fadeIn" data-wow-delay=".2s">
                            <div className="counter-style01 text-center">
                                <h5 className="display-1 font-weight-600">
                                    <span className="countup">961</span>+
                                </h5>
                                <h6 className="mb-0">Finished Projects</h6>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3 mt-2-9 wow fadeIn" data-wow-delay=".3s">
                            <div className="counter-style01 text-center">
                                <h5 className="display-1 font-weight-600">
                                    <span className="countup">16</span>+
                                </h5>
                                <h6 className="mb-0">Experience Years</h6>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3 mt-2-9 wow fadeIn" data-wow-delay=".4s">
                            <div className="counter-style01 text-center">
                                <h5 className="display-1 font-weight-600">
                                    <span className="countup">734</span>+
                                </h5>
                                <h6 className="mb-0">Happy clients</h6>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3 mt-2-9 wow fadeIn" data-wow-delay=".5s">
                            <div className="counter-style01 text-center">
                                <h5 className="display-1 font-weight-600">
                                    <span className="countup">4</span>K
                                </h5>
                                <h6 className="mb-0">Skilled Experts</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export { default as AboutPage } from "./about";
export { default as LoginPage } from "./login";
export { default as JoinPage } from "./join";
export { default as TransferPage} from './transfer'