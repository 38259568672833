import React, { useState, useContext } from "react";
import swal from "sweetalert";
import { useNavigate, useSearchParams, Link } from "react-router-dom";

import { AppContext } from "@component/AppProvider";

import _CFG from "@config/defaults";
import _API from "@util/api";
import _CK from "@util/cookie";

export default () => {
    const { saveIam } = useContext(AppContext);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const [id, setId] = useState("");
    const [passwd, setPasswd] = useState("");

    const onSubmit = (e) => {
        e.preventDefault();
        try {
            const url = searchParams.get("url");

            if (!id) {
                throw { message: "아이디를 입력해 주세요." };
            }
            if (!passwd) {
                throw { message: "비밀번호를 입력해 주세요." };
            }

            _API.post({ path: "/login", data: { id, passwd } })
                .then((res) => res.data)
                .then((data) => {
                    const { accessKey, cdn, iam } = data;
                    if (iam?.status !== "Y") {
                        swal("접근 권한이 없습니다.");
                        return;
                    }

                    saveIam(iam);
                    _CK.setAuth(accessKey);
                    navigate(url || "/", { replace: true });
                })
                .catch((e) => _API.alert(e));
        } catch (e) {
            swal({
                title: "알림",
                text: e.message,
            });
        }
    };

    return (
        <section className="bg-light p-0">
            <div className="container d-flex flex-column">
                <div className="row align-items-center justify-content-center min-vh-100">
                    <div className="col-sm-11 col-md-8 col-lg-7 col-xl-5">
                        <div className="card p-2-0 p-sm-2-5 my-2-0">
                            <div className="mb-4 text-center">
                                <h2 className="font-weight-normal mb-0">
                                    Welcome to <span className="text-primary">CarbonPay</span>
                                </h2>
                                <p className="mb-0">Login to your account.</p>
                            </div>

                            <form onSubmit={onSubmit}>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Username</label>
                                            <input type="text" className="form-control" name="username" placeholder="Your username" value={id} onChange={(e) => setId(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Password</label>
                                            <input type="password" className="form-control" name="password" placeholder="Your password" value={passwd} onChange={(e) => setPasswd(e.target.value)} />
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-2">
                                    <div className="col-sm-6 mb-2 mb-sm-0">
                                        <Link to="/join" className="text-primary">
                                            Register
                                        </Link>
                                    </div>
                                    <div className="col-sm-6 text-start text-sm-end">
                                        <a href="#" className="m-link-muted small">
                                            Forgot password?
                                        </a>
                                    </div>

                                    <div className="col-md-12 center">
                                        <button type="submit" className="butn style-one fill d-block w-100 mt-4 mb-0">
                                            Login
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
