import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import _CK from "@util/cookie";

import Layout from "@layout/app";

import IndexPage, { AboutPage, LoginPage, JoinPage, TransferPage } from "./pages";

export default () => {
    return (
        <Router>
            <Layout>
                <Routes>
                    <Route path="/" element={<IndexPage />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/join" element={<JoinPage />} />
                    <Route path="/wallet/:code" element={<TransferPage />} />
                    <Route path="*" element={<Navigate replace to="/" />} />
                </Routes>
            </Layout>
        </Router>
    );
};
